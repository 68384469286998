.navbar-top-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	padding: 10px 0;
	min-height: 40px;
	.tel-link {
		display: flex;
		align-items: center;
		line-height: 1;
		i {
			margin-right: 4px;
			transform: translateY(-1px) rotate(18deg);
			font-size: 16px;
			font-weight: 900;
		}
	}
	@media (max-width: 575px) {
		font-size: 14px;
	}
}
.mode--toggle {
	width: 42px;
	height: 20px;
	border-radius: 10px;
	background: rgba(141, 153, 174, 0.5);
	border-radius: 50px;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	z-index: 99;
	img {
		transition: all ease 0.3s;
		position: absolute;
		left: 2px;
		top: 1px;
		height: 18px;
		border-radius: 50%;
	}
}
.language-bar {
	color: var(--title-clr);
	button {
		outline: none;
		border: none;
		box-shadow: none;
		background: transparent;
		font-size: 15px;
		display: flex;
		align-items: center;
		img {
			width: 18px;
			height: 13px;
			object-fit: cover;
		}
		span {
			padding-left: 7px;
			color: var(--title-clr);
		}
	}
	position: relative;
	.lang-btn {
		padding: 3px 12px;
		margin: 0 10px;
		height: 20px;
	}
	ul {
		position: absolute;
		top: calc(100% + 10px);
		left: 0;
		padding: 10px 0;
		border-radius: 5px;
		box-shadow: 0 0 5px rgba(141, 153, 174, 0.5);
		transform: translateY(15px);
		visibility: hidden;
		opacity: 0;
		transition: all ease 0.4s;
		background: var(--white-clr);
		z-index: 99;
		li {
			padding: 0;
			button {
				height: 35px;
				padding: 0 27px 0 23px;
				span {
					color: var(--dark-clr);
					font-size: 14px;
				}
			}
		}
	}
	> button:focus ~ ul {
		transform: translateY(0);
		visibility: visible;
		opacity: 1;
	}
}
.navbar-bottom-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 22px;
	// background: var(--header-bg);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	-moz-backdrop-filter: blur(5px);
	-ms-backdrop-filter: blur(5px);
	-o-backdrop-filter: blur(5px);
	border-radius: 0px 0px 10px 10px;
	transition: all ease 0.3s;
	.menu {
		display: flex;
		margin-left: auto;
		transition: all ease 0.3s;
		li {
			padding: 2.5px;
			a {
				padding: 10px;
				font-size: 14px;
				color: var(--title-clr);
				text-transform: capitalize;
				span {
					position: relative;
					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: -9px;
						height: 2px;
						background: var(--base-clr);
						border-radius: 2px;
						width: 0;
						transition: 0.3s ease-in-out;
					}
				}
				&:hover,
				&.active {
					color: var(--base-clr);
					span::before,
					span::before {
						width: 100%;
					}
				}
			}
		}
		@media (max-width: 991.9px) {
			position: fixed;
			top: 70px;
			flex-direction: column;
			width: 100vw;
			height: calc(100vh - 70px);
			padding: 30px 20px;
			// background: var(--header-bg);
			background: #e3f2fc;
			right: 0;
			border-top: 1px solid var(--border-clr);
			margin: 0 !important;
			transform-origin: right;
			li {
				padding: 0;
				a {
					padding: 5px;
					display: block;
				}
			}
			max-width: 300px;
			&:not(.active) {
				transform: scaleX(0);
				opacity: 0;
				visibility: hidden;
			}
		}
	}
	.logo {
		height: 50px;
		// width: 220px;
		width: 125px;
		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}
	@media (max-width: 575px) {
		padding: 10px;
		.logo {
			width: 0;
			flex-grow: 1;
			margin-right: 10px;
			max-width: 125px;
		}
		.cmn--btn {
			font-size: 12px;
			padding: 3px 12px;
			font-weight: 400;
		}
	}
}
.navbar-top {
	background: rgba(var(--body-bg-rgb), 0.9);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	-moz-backdrop-filter: blur(5px);
	-ms-backdrop-filter: blur(5px);
	-o-backdrop-filter: blur(5px);
}
header {
	position: sticky;
	top: 0;
	z-index: 999;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 5px 20px -5px rgba(33, 43, 54, 0.1);
}
/*Dark CSS For Header*/
.dark-theme {
	.mode--toggle {
		img {
			transform: translateX(21px);
		}
	}
	.navbar-top-wrapper {
		.text--base {
			color: var(--white-clr) !important;
		}
	}
}
.nav-toggle {
	position: relative;
	cursor: pointer;
	width: 25px;
	height: 20px;
	span {
		position: absolute;
		display: inline-block;
		height: 2px;
		width: 100%;
		-webkit-transition: all ease 0.3s;
		-moz-transition: all ease 0.3s;
		transition: all ease 0.3s;
		background: var(--title-clr);
		left: 0;
		&:first-child {
			top: 0;
		}
		&:nth-child(2) {
			top: 52%;
			transform: translateY(-65%);
		}
		&:last-child {
			bottom: 0;
		}
	}
	&.active span {
		&:first-child {
			-webkit-transform: rotate(45deg) translate(3px, 10px);
			-ms-transform: rotate(45deg) translate(3px, 10px);
			transform: rotate(45deg) translate(3px, 10px);
		}
		&:nth-child(2) {
			opacity: 0;
		}
		&:last-child {
			-webkit-transform: rotate(-45deg) translate(3px, -10px);
			-ms-transform: rotate(-45deg) translate(3px, -10px);
			transform: rotate(-45deg) translate(3px, -10px);
		}
	}
}
